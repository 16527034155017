<template lang="pug">
h1 {{ $t('modalLogin.title_remember') }}
p {{ $t('modalLogin.description_remember') }}
.form.flex.flex-col.gap-4.mt-4
  BaseInputText(:label="$t('modalLogin.label_email')" v-model="user_email" :error="user_email_error")
  .alert-remember(v-if="showAlertSuccess") 
    BaseCustomIcon(nameIcon="mdiCheck" :size="16")
    .txt {{ lang.t('modalLogin.remember_success') }}
  .remember-psw 
    .link(@click="emit('setView', 'FormLogin')") {{ $t('modalLogin.button_return_login') }}
  .form-button(@click="rememberPsw") {{ $t('modalLogin.button_remember') }}
</template>

<script setup>
import { defineEmits, ref, computed, watch } from 'vue';
import validatorEmail from '@/composables/validator-email';
import { i18n } from '@/languages';
import { UsersRepository } from '@/repository/users.repository';
import BaseCustomIcon from '@/components/base/baseCustomIcon.vue';
const lang = computed(() => i18n.global);

const emit = defineEmits(['setView']);
const user_email = ref('');
const user_email_error = ref('')
const showAlertSuccess = ref(false)
const { isValidEmail } = validatorEmail();

const rememberPsw = () => {
  if (isValidEmail(user_email.value)) {
    UsersRepository.rememberPassword(user_email.value).then(() => {
      showAlertSuccess.value = true;
    }).catch(() => {
      user_email_error.value = lang.value.t('modalLogin.remember_error_email')
    })
  } else {
    user_email_error.value = lang.value.t('modalLogin.login_error_email')
  }
}

watch(user_email, () => user_email_error.value = '')



</script>

<style lang="scss" scoped>
h1 {
  @apply text-3xl font-bold;
}
p {
  @apply text-sm my-2;
}
.form {
  .remember-psw {
    @apply flex justify-end;
    .link {
      @apply text-sm text-right cursor-pointer text-gray-400 transition-colors inline-block;
      &:hover {
        @apply underline text-gray-600;
      }
    }
  }

  .form-button {
    @apply bg-indigo-600 text-center text-white py-3 rounded-lg text-lg cursor-pointer;
  }
}

.alert-remember {
  @apply flex items-center border-2 border-green-200 rounded-md pl-2 pr-4 py-1 text-xs bg-green-50 text-green-800 font-medium;
  .txt {
    @apply ml-2;
  }
}
</style>
