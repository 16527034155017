<template lang="pug">
Teleport(to="body")
  XyzTransition(xyz="left-100%")
    .left-nav(v-if="showNav")
      .nav
        ul
          LinkDownloads.link-downloads
          li(v-for="(link, index) in links", :key="index")
            router-link(
              :to="{ name: link.routeLink }",
              v-if="!['login', 'logout'].includes(link.routeName)"
            ) {{ link.routeName }}
            LinkLoggin(v-if="link.routeName == 'login'")
            LinkLogout(v-if="link.routeName == 'logout'")
</template>
<script>
import LinkLoggin from "../navbar/link-loggin.vue";
import LinkLogout from "../navbar/link-logout.vue";
import LinkDownloads from "../navbar/link-downloads.vue";
export default {
  name: "nav-left",
  emits: ["closeNavLeft"],
  components: {
    LinkLoggin,
    LinkLogout,
    LinkDownloads,
  },
  props: {
    showNav: {
      type: Boolean,
      default: false,
    },
    links: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    outsideCounterClick: 0,
  }),
  methods: {
    setClick() {
      if (this.outsideCounterClick > 0) {
        this.$emit("closeNavLeft");
      }
      this.outsideCounterClick++;
    },
  },
  watch: {
    showNav: {
      inmediate: true,
      handler(nValue) {
        if (nValue) {
          window.addEventListener("click", this.setClick);
        } else {
          this.outsideCounterClick = 0;
          window.removeEventListener("click", this.setClick);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.left-nav {
  @apply fixed top-0 bottom-0 left-0 z-10 w-full sm:hidden;

  .nav {
    @apply w-4/5 bg-white h-full relative border-r shadow-md flex;
    ul {
      @apply mt-8 px-4 w-full;
      li {
        & + li {
          @apply mt-4;
        }

        a {
          @apply block py-2 px-4 rounded-md w-full transition-colors;
          &:hover {
            @apply bg-blue-100;
          }
        }
      }
    }
    .link-downloads {
      @apply flex items-center justify-center;
    }
  }
}
</style>
