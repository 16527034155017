<template lang="pug">
h1 {{ $t('modalLogin.title_login') }}
p {{ $t('modalLogin.description') }}
.form.flex.flex-col.gap-4.mt-4
  BaseInputText(
    v-model="user_email",
    :label="$t('modalLogin.label_email')",
    :error="error_email",
    :disabled="isLoading"
  )
  BaseInputText(
    v-model="user_password",
    :label="$t('modalLogin.label_password')",
    :error="error_password",
    inputType="password",
    :disabled="isLoading"
  )
  .remember-psw 
    .link(@click="$emit('setView', 'FormRememberPsw')") {{ $t('modalLogin.button_remember_psw') }}
  .form-button(@click="intentLogin") {{ $t('modalLogin.button_init') }}
  .error(v-if="!!error_unauthorized") {{ error_unauthorized }}
baseGlobalLoader(:show="isLoading")
</template>

<script>
import { UsersRepository } from "@/repository/users.repository";
import emailValidator from "@/composables/validator-email";
import sleep from "@/composables/sleep";

export default {
  name: "card-login",
  emits: ["setView"],
  inject: ["setShowModalLogin"],
  data: () => ({
    isLoading: false,
    user_email: "",
    user_password: "",
    error_email: "",
    error_password: "",
    error_unauthorized: "",
  }),
  watch: {
    user_email() {
      this.error_email = "";
      this.error_unauthorized = "";
    },
    user_password() {
      this.error_password = "";
      this.error_unauthorized = "";
    },
  },
  methods: {
    async intentLogin() {
      this.isLoading = true;
      this.error_email = "";
      this.error_password = "";
      this.error_unauthorized = "";
      const { isValidEmail } = emailValidator();
      const validEmail = isValidEmail(this.user_email);
      if (!validEmail) {
        this.error_email = this.$t('modalLogin.login_error_email');
      }
      if (this.user_password.length < 3) {
        this.error_password = this.$t('modalLogin.login_error_password');
      }
      if (validEmail && this.user_password.length >= 3) await this.fetchLogin();
      this.isLoading = false;
    },

    async fetchLogin() {
      UsersRepository.login({
        user_email: this.user_email,
        password: this.user_password,
      })
        .then(async (response) => {
          this.setShowModalLogin(false);
          const { timer } = sleep();
          await timer(250);
          this.$store.dispatch("UserStore/autoLogin", {
            access_token: response.access_token,
            forceRedirect: true,
          });
        })
        .catch((e) => {
          this.error_unauthorized = this.$t('modalLogin.login_error_default');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  @apply text-3xl font-bold;
}
p {
  @apply text-sm my-2;
}
.form {
  .remember-psw {
    @apply flex justify-end;

    .link {
      @apply text-sm text-right cursor-pointer text-gray-400 transition-colors inline-block;
      &:hover {
        @apply underline text-gray-600;
      }
    }
  }

  .form-button {
    @apply bg-indigo-600 text-center text-white py-3 rounded-lg text-lg cursor-pointer;
  }
}

.error {
  @apply text-xs -mb-7 -mt-1 text-red-600;
}
</style>
