<template lang="pug">
li
  slot
</template>

<script>
export default {
  name: "navbar-link",
};
</script>

<style lang="scss" scoped>
li {
  @apply text-sm text-gray-500 cursor-pointer font-medium transition-colors;

  & + li {
    @apply ml-12;
  }

  &:hover {
    @apply text-blue-600;
  }
}
</style>
