<template lang="pug">
.box-inline-icon(@click="showNavMenu = true")
  BaseCustomIcon(nameIcon="mdiMenu")
navLeft(
  :showNav="showNavMenu",
  :links="links",
  v-on:closeNavLeft="showNavMenu = false"
)
</template>
<script>
import navLeft from "./nav-left.vue";
export default {
  name: 'ButtonNavLeft',
  components: {
    navLeft
  },
  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    showNavMenu: false,
  })
}
</script>

<style lang="scss" scoped>
.box-inline-icon {
  @apply rounded-full p-1 cursor-pointer text-blue-400 sm:hidden;
  &:hover {
    @apply bg-blue-100;
  }
}
</style>
