<template lang="pug">
teleport(to="body")
  XyzTransition(xyz="fade up-1")
    .modal-wrapper(v-if="showModal")
      .background-modal
      .content
        .card
          BaseCustomIcon.close(
            nameIcon="mdiClose",
            :size="26",
            @click="$emit('closeModal')"
          )
          component(:is="componentSelected", v-on:setView="setView")
</template>

<script>
import FormLogin from "./card-login.vue";
import FormRememberPsw from "./card-remember-password.vue";
export default {
  name: "modal-login",
  emits: ["closeModal"],
  components: {
    FormLogin,
    FormRememberPsw,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    componentSelected: "FormLogin",
  }),

  watch: {
    showModal(n) {
      this.setBodyScroll(!n);
      this.componentSelected = "FormLogin";
    },
  },
  methods: {
    setView(v) {
      this.componentSelected = v;
    },
    setBodyScroll(scrollable) {
      const body = document.getElementsByTagName("body")[0];
      if (!scrollable) {
        body.style.overflow = "hidden";
        body.style["max-height"] = "100vh";
        body.style["padding-right"] = "17px";
      } else {
        setTimeout(() => {
          body.style.overflow = "visible";
          body.style["max-height"] = "100%";
          body.style["padding-right"] = "0";
        }, 500);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-wrapper {
  @apply fixed bottom-0 top-0 left-0 right-0 z-10 flex flex-col;
}

.background-modal {
  z-index: -1;
  @apply absolute top-0 left-0 right-0 bottom-0 bg-white opacity-60;
}

.content {
  @apply flex-grow h-full w-full items-center justify-center flex p-4;
}

.card {
  @apply bg-white rounded-xl px-8 py-12 shadow-md w-full overflow-auto max-w-md max-h-full relative;

  .close {
    @apply absolute top-4 right-4 bg-gray-100 rounded-full text-gray-300 cursor-pointer transition-colors;
    &:hover {
      @apply text-gray-500;
    }
  }
}
</style>

