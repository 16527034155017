<template lang='pug'>
navBar
routerView
navFooter
</template>
<script>
import navBar from "@/components/structure/navbar/navBar";
import navFooter from "@/components/structure/navFooter/navFooter";

export default {
  components: {
    navBar,
    navFooter,
  },
  setup() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
</style>