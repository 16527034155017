<template lang="pug">
ul
  LinkDownloads
  li(v-for="(link, index) in links", :key="index")
    router-link(
      :to="{ name: link.routeLink }",
      v-if="!['login', 'logout'].includes(link.routeName)"
    ) {{ link.routeName }}
    LinkLoggin(v-if="link.routeName == 'login'")
    LinkLogout(v-if="link.routeName == 'logout'")
</template>

<script>
import LinkLoggin from "./link-loggin.vue";
import LinkLogout from "./link-logout.vue";
import LinkDownloads from "./link-downloads.vue";
export default {
  name: "nav-inline",
  components: {
    LinkLoggin,
    LinkLogout,
    LinkDownloads,
  },
  props: {
    links: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  @apply flex items-center;
}
li {
  @apply text-sm text-gray-500 cursor-pointer font-medium transition-colors;

  & + li {
    @apply ml-12;
  }

  &:hover {
    @apply text-blue-600;
  }
}
</style>
