<template lang="pug">
.logout-button(@click="logout") {{ $t('navLinks.logout') }}
  BaseCustomIcon.ml-2.-mr-2(nameIcon="mdiAccount", :size="20")
</template>

<script>
export default {
  name: 'link-logout',
  inject: ['logout']
};
</script>

<style lang="scss" scoped>
.logout-button {
  @apply text-gray-600 bg-white ring-2 ring-inset ring-gray-200 flex items-center justify-center pl-4 pr-5 py-2 rounded-md transition-all cursor-pointer;
  &:hover {
    @apply text-red-800;
  }
}
</style>
