<template lang="pug">
.login-button(@click="setShowModalLogin(true)") {{ $t('navLinks.login') }}
  BaseCustomIcon.ml-2.-mr-2(nameIcon="mdiAccount", :size="20")
</template>

<script>
export default {
  name: 'link-loggin',
  inject: ['setShowModalLogin']
};
</script>

<style lang="scss" scoped>
.login-button {
  @apply bg-indigo-600 text-white flex items-center justify-center font-medium pl-4 pr-5 py-2 rounded-md transition-colors cursor-pointer;
  &:hover {
    @apply bg-blue-700;
  }
}
</style>
